<template>
  <div>
    <!--  待处理列表-->
    <div v-for="(item, index) in pending_list" :key="index">
        <div v-if="this.bdst === '1' || this.bdst.split(',').length > 1">
            <div id="dc_div1" @click="goto_detail(item.id, item.materialEquiment)">
	            <div class="div_font" style="color: red" v-if="item.materialEquiment === 0">
		            调拨单类型：物资调拨
	            </div>
	            <div class="div_font" style="color: red" v-if="item.materialEquiment === 1">
		            调拨单类型：设备配件调拨
	            </div>
                <div class="div_font">
                    调出工区：{{ item.dcBdst }}
                </div>
                <div class="div_font">
                    调入工区：{{ item.drBdst }}
                </div>
                <div class="div_font">
                    调拨材料类别：{{ item.dbdType }}
                </div>
                <div class="div_font">
	                调拨编号：{{ item.dbdNum }}
                </div>
                <div>
                    调拨材料时间：{{ item.dbdTime }}
                </div>
            </div>
        </div>
        <div v-if="this.bdst !== '1'">
            <div v-if="this.bdst === item.dcBdst" id="dc_div" @click="goto_detail(item.id, item.materialEquiment)">
	            <div class="div_font" style="color: red" v-if="item.materialEquiment === 0">
		            调拨单类型：物资调拨
	            </div>
	            <div class="div_font" style="color: red" v-if="item.materialEquiment === 1">
		            调拨单类型：设备配件调拨
	            </div>
                <div class="div_font">
                    调拨类别：调出
                </div>
                <div class="div_font">
                    调拨材料类别：{{ item.dbdType }}
                </div>
                <div class="div_font">
	                调拨编号：{{ item.dbdNum }}
                </div>
                <div>
                    调拨材料时间：{{ item.dbdTime }}
                </div>
            </div>
            <div v-if="this.bdst === item.drBdst" id="dr_div" @click="goto_detail(item.id, item.materialEquiment)">
	            <div class="div_font" style="color: red" v-if="item.materialEquiment === 0">
		            调拨单类型：物资调拨
	            </div>
	            <div class="div_font" style="color: red" v-if="item.materialEquiment === 1">
		            调拨单类型：设备配件调拨
	            </div>
                <div class="div_font">
                    调拨类别：调入
                </div>
                <div class="div_font">
                    调拨材料类别：{{ item.dbdType }}
                </div>
                <div class="div_font">
	                调拨编号：{{ item.dbdNum }}
                </div>
                <div>
                    调拨材料时间：{{ item.dbdTime }}
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {Burger} from "@element-plus/icons";
import {mapGetters} from "vuex";

export default {
  name: "material_pending",
  components: {Burger},
  inject: ['reload'],
  data() {
    return {
      pending_list: [],
      bdst: '',
      user_id: ''
    }
  },
  created() {
	  this.user_id = this.$route.params.user_id
	  // this.user_id = '828gXLp1'
    this.get_pending()
  },
  methods: {
    get_pending() {
      this.axios.post('/materialDbdMain/getPendingByUser', (response) => {
        this.pending_list = response.obj
        this.bdst = response.obj2.bdst
      }, {
       user_id: this.user_id
      })
    },
    goto_detail(item, flag){
      this.$router.push({
        name: 'Material_dbd_detail',
        params: {
          dbd_id: item,
          user_id: this.user_id,
	        material_equiment: flag
        }
      });
    }
  }
}
</script>

<style scoped>
.div_font {
  margin-bottom: 5%
}

#dc_div {
  background-color: #00ffc4;
  height: 25vh;
  margin-top: 5%;
  padding-top: 3%;
	padding-left: 3%;
  font-weight: bolder;
  font-size: 1rem
}

#dc_div1 {
    background-color: #00ffc4;
    height: 28vh;
    margin-top: 5%;
    padding-top: 3%;
	padding-left: 3%;
    font-weight: bolder;
    font-size: 1rem
}
#dr_div {
  background-color: #ff0026;
  height: 25vh;
  margin-top: 5%;
  padding-top: 3%;
	padding-left: 3%;
  font-weight: bolder;
  font-size: 1rem
}
</style>
